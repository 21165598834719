// @flow
import React from 'react';

import './Button.css';

type Props = {
  text: string,
  icon?: string,
  classIcon: string,
  buttonType:
    | string
    | 'button-primary'
    | 'button-tertiary'
    | 'button-secondary'
    | 'button-outline',
  loading: boolean,
  disabled: boolean,
  onClick: () => void,
};

const Button = (props: Props) => {
  let text = props.text;
  if (props.loading) {
    text = 'Carregando...';
  }
  const disabled = props.loading | props.disabled;

  return (
    <button
      className={`button-main ${props.buttonType} ${
        (disabled && 'button-disabled') || ''
      }`}
      onClick={props.onClick}
      disabled={disabled}
    >
      {!!props.icon ? (
        <div className="button-row">
          <img className="button-icon" alt="" src={props.icon} /> &nbsp;{' '}
          {props.text}
        </div>
      ) : (
        <div className="button-row">
          {props.loading && (
            <i className="fa fa-spinner fa-pulse fa-1x" aria-hidden="true" />
          )}
          <span className="button-just-text">{text}</span>
        </div>
      )}
    </button>
  );
};

export default Button;
